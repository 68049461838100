import styles from "./styles.module.css";
import { DeliveryItemInterface } from "../../constant/interfaces";

interface TableDeliveryInterface {
    deliveryData: DeliveryItemInterface[];
}

const TableDelivery = ({ deliveryData }: TableDeliveryInterface) => {
    return (
        <>
            <h2
                className={styles.sectionTitle}
            >{`Deliveries (${deliveryData.length} items)`}</h2>
            <table>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Date</th>
                    <th className={styles.cellCenter}>{`Duration (day)`}</th>
                    <th>Truck</th>
                </tr>
                {deliveryData.map((val) => (
                    <tr>
                        <td>{val.id}</td>
                        <td>{val.title}</td>
                        <td>{val.deliveryDate.toDateString()}</td>
                        <td className={styles.cellCenter}>
                            {val.deliveryDurationDay}
                        </td>
                        <td>{val.truck}</td>
                    </tr>
                ))}
            </table>
        </>
    );
};

export default TableDelivery;
