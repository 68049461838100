import { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.css";

import {
    DeliveryItemInterface,
    TruckItemInterface,
} from "../../constant/interfaces";

import TableTruck from "../../components/TableTruck";
import TableDelivery from "../../components/TableDelivery";

const DEFAULT_TOTAL_DAYS = 30;
const DEFAULT_DELIVERY_DURATION_DAY = 3;
const DEFAULT_MAINTENANCE_DURATION_DAY = 1;

const TODAY_MILIS = new Date().getTime();

const HomeScreen = () => {
    const [deliveryData, setDeliveryData] = useState<
        Array<DeliveryItemInterface>
    >([]);
    const [truckFleet, setTruckFleet] = useState<Array<TruckItemInterface>>([]);
    const [totalDays, setTotalDays] = useState<number>(DEFAULT_TOTAL_DAYS);
    const [deliveryDurationDay, setDeliveryDurationDay] = useState<number>(
        DEFAULT_DELIVERY_DURATION_DAY
    );
    const [maintenanceDurationDay, setMaintenanceDurationDay] =
        useState<number>(DEFAULT_MAINTENANCE_DURATION_DAY);

    const simulateDeliverySchedule = useCallback(() => {
        const deliveryData: DeliveryItemInterface[] = [];
        // populate dummy delivery data
        // further development -> inputted by admin or from CSV or anything for real data (various delivery duration)
        // if real data inputted -> gonna need sort based on delivery Date
        for (let i = 0; i < totalDays; i += 1) {
            deliveryData.push({
                id: `deliv_id_${i + 1}`,
                deliveryDate: new Date(TODAY_MILIS + i * 86400000),
                deliveryDurationDay: deliveryDurationDay,
                title: `Delivery Day-${i + 1}`,
                truck: "",
            });
        }

        const truckFleet: TruckItemInterface[] = [];
        // populate truck fleet data
        deliveryData.forEach((deliveryItem, deliveryItemIndex) => {
            if (truckFleet.length === 0) {
                // add first truck
                const idTruck = `truck_id_1`;
                truckFleet.push({
                    id: idTruck,
                    onDeliveryDay: deliveryItem.deliveryDurationDay,
                    onMaintenanceDay: maintenanceDurationDay,
                    deliveries: [deliveryItem.id],
                });
                deliveryData[deliveryItemIndex].truck = idTruck;
                return;
            }

            // subtract one day for each truck
            truckFleet.forEach((truck, truckIndex) => {
                if (truck.onDeliveryDay > 0) {
                    truckFleet[truckIndex].onDeliveryDay =
                        truck.onDeliveryDay - 1;
                    return;
                }

                if (truck.onMaintenanceDay > 0) {
                    truckFleet[truckIndex].onMaintenanceDay =
                        truck.onMaintenanceDay - 1;
                    return;
                }
            });

            // find truck for new delivery
            let foundAvailableTruck = false;
            truckFleet.forEach((truck, truckIndex) => {
                if (foundAvailableTruck) return;
                if (truck.onDeliveryDay === 0 && truck.onMaintenanceDay === 0) {
                    truckFleet[truckIndex].deliveries.push(deliveryItem.id);
                    truckFleet[truckIndex].onDeliveryDay =
                        deliveryItem.deliveryDurationDay;
                    truckFleet[truckIndex].onMaintenanceDay =
                        maintenanceDurationDay;
                    foundAvailableTruck = true;

                    deliveryData[deliveryItemIndex].truck = truck.id;
                }
            });
            if (foundAvailableTruck) return;

            // available truck not found -> add new truck
            const idTruck = `truck_id_${truckFleet.length + 1}`;
            truckFleet.push({
                id: `truck_id_${truckFleet.length + 1}`,
                onDeliveryDay: deliveryItem.deliveryDurationDay,
                onMaintenanceDay: maintenanceDurationDay,
                deliveries: [deliveryItem.id],
            });
            deliveryData[deliveryItemIndex].truck = idTruck;
        });

        setDeliveryData(deliveryData);
        setTruckFleet(truckFleet);
    }, [deliveryDurationDay, maintenanceDurationDay, totalDays]);

    useEffect(() => {
        simulateDeliverySchedule();
    }, [simulateDeliverySchedule]);

    return (
        <div className={styles.container}>
            <h2 className={styles.sectionTitle}>Variables</h2>
            <p>Total Days: {totalDays} days</p>
            <input
                type="range"
                min="1"
                max="100"
                value={totalDays}
                onChange={(event) => setTotalDays(Number(event.target.value))}
            />
            <p>Delivery Duration for 1 shipping: {deliveryDurationDay} days</p>
            <input
                type="range"
                min="1"
                max="30"
                value={deliveryDurationDay}
                onChange={(event) =>
                    setDeliveryDurationDay(Number(event.target.value))
                }
            />
            <p>Maintenance Duration: {maintenanceDurationDay} days</p>{" "}
            <input
                type="range"
                min="1"
                max="30"
                value={maintenanceDurationDay}
                onChange={(event) =>
                    setMaintenanceDurationDay(Number(event.target.value))
                }
            />
            <TableTruck deliveryData={deliveryData} truckFleet={truckFleet} />
            <TableDelivery deliveryData={deliveryData} />
        </div>
    );
};

export default HomeScreen;
