import styles from "./styles.module.css";
import {
    DeliveryItemInterface,
    TruckItemInterface,
} from "../../constant/interfaces";

interface TableTruckInterface {
    deliveryData: DeliveryItemInterface[];
    truckFleet: TruckItemInterface[];
}

const TableTruck = ({ deliveryData, truckFleet }: TableTruckInterface) => {
    const getDeliveryById = (delivId: string) => {
        const choosenIndex = deliveryData.findIndex(
            (val) => val.id === delivId
        );
        if (choosenIndex === -1) return "-";
        return deliveryData[choosenIndex].deliveryDate.toDateString();
    };
    return (
        <>
            <h2
                className={styles.sectionTitle}
            >{`Truck Fleet (${truckFleet.length} trucks)`}</h2>
            <table>
                <tr>
                    <th rowSpan={2}>Truck ID</th>
                    <th colSpan={2} className={styles.cellCenter}>
                        Deliveries
                    </th>
                </tr>
                <tr>
                    <th>Delivery ID</th>
                    <th>Delivery Date</th>
                </tr>
                {truckFleet.map((val) => (
                    <>
                        <tr>
                            <td rowSpan={val.deliveries.length}>{val.id}</td>
                            <td>{val.deliveries[0]}</td>
                            <td>{getDeliveryById(val.deliveries[0])}</td>
                        </tr>
                        {val.deliveries
                            .slice(1, val.deliveries.length)
                            .map((idDeliv) => (
                                <tr>
                                    <td>{idDeliv}</td>
                                    <td>{getDeliveryById(idDeliv)}</td>
                                </tr>
                            ))}
                    </>
                ))}
            </table>
        </>
    );
};

export default TableTruck;
